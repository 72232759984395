import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function Notification() {
  const { notification } = useSelector((state) => state.globalState);

  const showNotification = () => {
    if (!notification?.open) return;
    const options = {
      position: notification?.anchorOrigin || "top-right",
      autoClose: notification?.duration || 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      onClose: () => notification?.handleClose()
    }
    switch (notification?.severity || notification?.variant) {
      case "success": return toast.success(<div className="text-body"><h2>{notification?.title}</h2><p>{notification?.body}</p></div>, options)
      case "warning": return toast.warning(<div className="text-body"><h2>{notification?.title}</h2><p>{notification?.body}</p></div>, options)
      case "error": return toast.error(<div className="text-body"><h2>{notification?.title}</h2><p>{notification?.body}</p></div>, options)
      case "info": return toast.info(<div className="text-body"><h2>{notification?.title}</h2><p>{notification?.body}</p></div>, options)
      default: return toast.info(<div className="text-body"><h2>{notification?.title}</h2><p>{notification?.body}</p></div>, options)
    }
  };

  useEffect(() => {
    showNotification();
  }, [notification]);
}
