import APISources from "../sources"
import instance from "../utils/axios";
import * as apiUrls from "../lambdaApiUrls";

export const getCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createCustomerAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getCustomerDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateCustomerAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteCustomerAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateCustomerStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.CustometStatusURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.ServicesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.PartnersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAgentsAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.AgentsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAddonsAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.AddonsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const uploadContractFileAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.UploadFileURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createFileAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.CreateFileDataURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteFileAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.RemoveFileDataURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const extendTrialAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.ExtendTrialURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnerSubscriptionsAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.PartnerSubscriptionsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAgentSubscriptionsAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.AgentSubscriptionsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.ListPartners}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listSalesPersonAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.ListSalesPerson}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAgentListAsync = async (data, dispatch) => await instance.get(`${apiUrls?.agentUrl}?partner=${data?.partner}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const getSoftwaresByServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.SoftwaresByServiceURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getOtherServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Customers.GetOtherServiceList}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })