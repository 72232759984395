import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomersAsync,
  createCustomerAsync,
  getServicesAsync,
  getPartnersAsync,
  getAgentsAsync,
  getAddonsAsync,
  getCustomerDetailsAsync,
  updateCustomerAsync,
  deleteCustomerAsync,
  updateCustomerStatusAsync,
  uploadContractFileAsync,
  createFileAsync,
  deleteFileAsync,
  extendTrialAsync,
  getPartnerSubscriptionsAsync,
  listFilterPartnersAsync,
  listSalesPersonAsync,
  getAgentSubscriptionsAsync,
  getAgentListAsync,
  getSoftwaresByServiceAsync,
  getOtherServicesAsync
} from "../api/customers";

const CustomerContext = createContext();
export const CustomersProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getCustomers = async (data) => await getCustomersAsync(data, dispatch);
  const createCustomer = async (data) => await createCustomerAsync(data, dispatch);
  const getCustomerDetails = async (data) => await getCustomerDetailsAsync(data, dispatch);
  const updateCustomer = async (data) => await updateCustomerAsync(data, dispatch);
  const deleteCustomer = async (data) => await deleteCustomerAsync(data, dispatch);
  const updateCustomerStatus = async (data) => await updateCustomerStatusAsync(data, dispatch);
  const uploadContractFile = async (data) => await uploadContractFileAsync(data, dispatch);
  const getServices = async (data) => await getServicesAsync(data, dispatch);
  const getPartners = async (data) => await getPartnersAsync(data, dispatch);
  const getAgents = async (data) => await getAgentsAsync(data, dispatch);
  const getAddons = async (data) => await getAddonsAsync(data, dispatch);
  const createFile = async (data) => await createFileAsync(data, dispatch);
  const deleteFile = async (data) => await deleteFileAsync(data, dispatch);
  const extendTrial = async (data) => await extendTrialAsync(data, dispatch);
  const getPartnerSubscriptions = async (data) => await getPartnerSubscriptionsAsync(data, dispatch);
  const getAgentSubscriptions = async (data) => await getAgentSubscriptionsAsync(data, dispatch);
  const listFilterPartners = async (data) => await listFilterPartnersAsync(data, dispatch);
  const listSalesPerson = async (data) => await listSalesPersonAsync(data, dispatch);
  const getAgentList = async (data) => await getAgentListAsync(data, dispatch);
  const getSoftwaresByService = async (data) => await getSoftwaresByServiceAsync(data, dispatch);
  const getOtherServices = async (data) => await getOtherServicesAsync(data, dispatch);

  const values = {
    getOtherServices,
    getSoftwaresByService,
    getCustomers,
    createCustomer,
    getCustomerDetails,
    updateCustomer,
    deleteCustomer,
    updateCustomerStatus,
    getServices,
    getPartners,
    getAgents,
    getAddons,
    uploadContractFile,
    createFile,
    deleteFile,
    extendTrial,
    getPartnerSubscriptions,
    getAgentSubscriptions,
    listFilterPartners,
    listSalesPerson,
    getAgentList
  };

  return (
    <CustomerContext.Provider value={values}>{children}</CustomerContext.Provider>
  );
};
export default CustomerContext;