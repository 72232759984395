// constants.js

// Service Names
export const SERVICE_NAMES = {
    QTO: 'QTO',
    FTE: 'FTE',
    OTHERS: 'Others',
};

//User Role
export const USER_ROLE = {
    USER: "User",
    CUSTOMER_ADMIN: "Customer Admin",
    BRANCH_ADMIN: "Branch Admin",
}

// Filter Constants
export const FILTER_CONSTANTS = {
    IDS: {
        CUSTOMER: "customer",
        PARTNER: 'partner',
        BRANCH: "branch",
        USER: "user",
        STATUS: "status",
        AGENT: 'agent',
        ROLE: 'role',
        SALES_PERSON: "sales_person",
        SUBSCIPTION_TYPE: "subscription_type",

    },
    LABELS: {
        CUSTOMER: "Customer",
        PARTNER: 'Partner',
        BRANCH: "Branch",
        USER: "User",
        STATUS: "Status",
        AGENT: 'Agent',
        ROLE: 'Role',
        SALES_PERSON: "Sales Person",
        SUBSCIPTION_TYPE: "Subscription Type",
    },
};

//LABEL
export const LABEL = {
    PROJECT_CODE: "Project Code",
    CLIENT_PROJECT_NUMBER: "Client Project Number"
}

//PLACEHOLDER
export const PLACEHOLDER = {
    CLIENT_PROJECT_NUMBER: "Enter Client Project Number"
}

// Statuses
export const STATUSES = {
    IDS: {
        ACTIVE: 'active',
        INACTIVE: 'inactive',
        PENDING: 'pending',
    },
    LABELS: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        PENDING: 'pending',
    }
};

// Date Formats
export const DATE_FORMATS = {
    DEFAULT: 'YYYY-MM-DD',
    DISPLAY: 'DD/MM/YYYY',
};

//Date 
export const DATE_CONSTANTS = {
    LABELS: {
        YEAR: "Year",
        MONTH: "Month",
        DAY: "Day",
    },
    IDS: {
        YEAR: "year",
        MONTH: "month",
        DAY: "day",
    },
};