import React, { createContext, useEffect, useState } from "react";
import { FILTER_ARGS_ACTION, SIDEBAR_NAVIGATION_ITEMS, USER_NOTIFICATION } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Navigations from "../pages/_nav";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [{ user_permissions, user }] = useSelector((state) => [state.authUser]);
  const ratingGiven = JSON.parse(sessionStorage.getItem('ratingGiven'))

  useEffect(() => {
    const filteredNavigations = Navigations(user_permissions, user);
    filteredNavigations.forEach((item) => {
      item.disabled = ratingGiven;
    });

    dispatch({
      type: SIDEBAR_NAVIGATION_ITEMS,
      payload: filteredNavigations
    });
  }, [user_permissions, dispatch, ratingGiven]);

  const checkPermission = (module, action) => {
    return user_permissions && user_permissions?.[module]?.[action]
  }

  const sendNotification = ({ title, body, type, duration, severity, anchorOrigin, actionHandler }) => {
    if (severity) {
      dispatch({
        type: USER_NOTIFICATION,
        payload: {
          open: true,
          title: title,
          body: body,
          type: false,
          // variant: 'success',
          variant: severity,
          // variant: 'warning',
          duration: duration,
          anchorOrigin: anchorOrigin,
          handleClose: (actionHandler ? actionHandler : () => { })
        },
      });
    } else if (type) {
      dispatch({
        type: USER_NOTIFICATION,
        payload: {
          open: true,
          title: title,
          body: body,
          type: false,
          // variant: 'success',
          variant: 'error',
          // variant: 'warning',
          duration: duration,
          anchorOrigin: anchorOrigin,
          handleClose: (actionHandler ? actionHandler : () => { })
        },
      });
    } else {
      dispatch({
        type: USER_NOTIFICATION,
        payload: {
          open: true,
          title: title,
          body: body,
          type: false,
          // variant: 'success',
          variant: 'error',
          // variant: 'warning',
          duration: duration,
          anchorOrigin: anchorOrigin,
          handleClose: (actionHandler ? actionHandler : () => { })
        },
      });
    }
  }

  const SetFilterArgs = (filter_args) => {
    dispatch({
      type: FILTER_ARGS_ACTION,
      payload: filter_args
    })
  }

  const values = {
    checkPermission,
    sendNotification,
    SetFilterArgs
  }
  return <PermissionsContext.Provider value={values}>{children}</PermissionsContext.Provider>;
};

export default PermissionsContext;