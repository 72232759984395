import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import {
  UploadPartnerFileAsync,
  createPartnerAsync,
  deletePartnerAsync,
  getPartnerDetailsAsync,
  getPartnerServicesAsync,
  getPartnersAsync,
  updatePartnerAsync,
  getAddonsAsync,
  createFileAsync,
  deleteFileAsync,
  serviceDetailsAsync,
  getSoftwaresByServiceAsync,
  getPriceBySortwareAsync,
  getotherservicesAsync
} from "../api/partners";

const PartnerContext = createContext();

export const PartnerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const getPartners = async (data) => await getPartnersAsync(data, dispatch);
  const createPartner = async (data) => await createPartnerAsync(data, dispatch);
  const getPartnerDetails = async (data) => await getPartnerDetailsAsync(data, dispatch);
  const updatePartner = async (data) => await updatePartnerAsync(data, dispatch);
  const deletePartner = async (data) => await deletePartnerAsync(data, dispatch);
  const uploadPartnerFile = async (data) => await UploadPartnerFileAsync(data, dispatch);
  const getPartnerServices = async (data) => await getPartnerServicesAsync(data, dispatch);
  const getAddons = async (data) => await getAddonsAsync(data, dispatch);
  const createFile = async (data) => await createFileAsync(data, dispatch);
  const deleteFile = async (data) => await deleteFileAsync(data, dispatch);
  const getServicesDetail = async (data) => await serviceDetailsAsync(data, dispatch);
  const getSoftwaresByService = async (data) => await getSoftwaresByServiceAsync(data, dispatch);
  const getPriceBySortware = async (data) => await getPriceBySortwareAsync(data, dispatch);
  const getOtherServices = async (data) => await getotherservicesAsync(data, dispatch);

  const values = {
    getOtherServices,
    getSoftwaresByService,
    getPriceBySortware,
    getPartners,
    createPartner,
    getPartnerDetails,
    updatePartner,
    deletePartner,
    uploadPartnerFile,
    getPartnerServices,
    getAddons,
    createFile,
    deleteFile,
    getServicesDetail
  };

  return (
    <PartnerContext.Provider value={values}>{children}</PartnerContext.Provider>
  );
};

export default PartnerContext;